export default {
  GREETING: 'Hello {name}',
  // resources
  'resources.organization': 'organization',
  'resources.organization.title': 'organization/company name',
  'resources.organization.select.label': 'Select your company',
  'resources.organization.create.button': 'Create new company',
  //
  //header
  'header.links.dashboard': 'Dashboard',
  'header.links.customers': 'Customers',
  'header.links.campaigns': 'Campaigns',
  'header.links.changeOrganization': 'Switch Company',

  'resources.customers': 'customers',

  'customers.page.title': 'Your Customers',
  'customers.table.count': 'Available Customers: {count}',
  // actions
  'actions.search': 'search',
  'actions.create': 'create',
  //user
  'user.profile.changePassword': 'Change password',

  'actions.create.resource': 'create {resource}',
  // forms
  'forms.fields.common.logo': 'Logo',
  'forms.fields.common.website': 'website',
  'forms.fields.common.description': 'description',
  'forms.fields.file': 'file',
  'forms.fields.currentPass': 'Current Password*',
  'forms.fields.newPass': 'New Password*',
  'forms.fields.repeatPass': 'Repeat Password*',
  // validation
  'forms.validation.required': 'This field is required',
  'forms.validation.repeatPass':
    'Repeat password is not equal with new password',
  //
  'organizations.create': 'new organization',
  // customer
  customer: 'customers',
  'customer.profile.level': 'Level',
  'customer.profile.score': 'Score',
  'customer.profile.details.basicInfo.header': 'Basic Info',
  'customer.profile.details.additionalInfo.header': 'Additional Info',
  'customer.profile.details.basicInfo.empty': 'No info yet!',
  'customer.profile.details.basicInfo.edit.header': 'Edit basic info',
  'customer.profile.details.additionalInfo.edit.header': 'Edit Additional Info',
  'customer.profile.details.tag.header': 'Tag',
  'customer.profile.details.projects.header': 'Last Orders/Projects',
  'customer.profile.details.notes.header': 'Notes',
  'customer.profile.details.notes.add': 'Add Note',
  'customer.profile.details.notes.add.placeholder': 'Your note ...',
  'customer.profile.details.notes.writer': 'Writer: ',
  'customer.profile.details.lastActivities.header': 'Last Activities',
  'customer.profile.details.lastActivities.order': 'Ordered {type}',
  'customer.profile.details.lastActivities.campaign': 'Campaign Created {type}',
  'customer.profile.details.lastActivities.payment': 'Paid {type}',
  'customer.profile.details.lastActivities.support':
    'Created/ edited support ticket {type}',
  'customer.profile.details.lastActivities.support_reply':
    'Answered Support {type}',

  'customer.profile.details.Activity.header': 'activity',
  'customer.profile.details.Activity.viewAll': 'View All →',
  'customer.profile.details.fields.viewAll': 'View All Form Fields',
  'customer.profile.details.fields.add': '{icon} Add Field',
  'customer.profile.allFields.title': 'Field Title',
  'customer.profile.allFields.value': 'Field Value',
  'customer.profile.allFields.displayingInProfile':
    'Display In Customer Profile',
  'customer.profile.allFields.userLink': 'All Form Fields of {customer}',

  'customer.activities.header': 'Activity list of {customer}',
  'customer.orders.header': 'Order/Project list of {customer}',
  'customer.activities.empty': 'No activities yet!',
  'customer.orders.empty': 'No orders/projects yet!',
  'customer.activities.table.message': ' Activity',
  'customer.activities.table.type': ' Type',
  'customer.activities.table.type.order': 'Order',
  'customer.activities.table.type.campaign': 'Create campaign',
  'customer.activities.table.type.payment': 'Payment',
  'customer.activities.table.type.support': 'Create/Edit support ticket',
  'customer.activities.table.type.support_reply': 'Answered support ticket',
  'customer.activities.table.date': ' Date',
  'customer.activities.table.type.submit': 'Submit form',
  'customer.activities.table.type.create': 'Create',

  'customer.notes.header': "{customer}'s notes",
  'customer.notes.noteText': 'note',
  'customer.notes.date': 'created date',
  'customer.notes.emptyList': 'No notes yet!',

  'customer.field.title': 'Field Title',
  'customer.field.type': 'Field Type',
  'customer.field.choiceItem.label': 'Option {count}',
  'customer.delete.successfully': 'The customer removed successfully.',
  'customer.delete.areYouSure': 'Are you sure to delete the customer?',
  'customer.gamification.data.empty':
    'There is no gamification data for this customer!',
  'customer.gamification.F': 'How often does the customer purchase? (Avg)',
  'customer.gamification.R': 'How recently did the customer purchase?',
  'customer.gamification.S': 'Score',
  'customer.gamification.AS': 'Avg of all Levels of previous months',
  'customer.gamification.NO': 'Number of orders of the customer',
  'customer.gamification.TM': 'Total Monetary Value',
  'customer.gamification.NS': 'New scores',
  'customer.gamification.PF': 'PF',
  'customer.gamification.PR': 'PR',
  'customer.gamification.AQS': 'Avg Level of previous 3 months',
  'customer.gamification.NoP': 'Number of payments of the customer',
  'customer.gamification.cashBack': 'Cashback',
  'customer.gamification.cashBack.currency': '$ {value}',
  'tags.clearAll': 'Clear All Tags',
  'tags.edit': 'Edit Tag',
  'tags.title': 'Title',
  'tags.create': 'Create new tag',
  'tags.create.success.message': 'New tag created successfully',
  'tags.withCustomer': 'Tags which include customers',

  'field.email.invalid': 'Email is not valid',
  'field.general.invalid': 'Enter valid {field}',
  'field.number.label': 'Number',
  'field.phone.label': 'Phone',
  'field.money.label': 'Money',
  'field.email.label': 'Email',
  'field.website.label': 'Website',
  'field.shortText.label': 'Short Text',
  'field.longText.label': 'Long Text',
  'field.multipleSelect.label': 'Multiple Choice',
  'field.choice.label': 'Single Choice',
  'field.dropdown.label': 'Dropdown',
  'field.choiceItems.label': 'Options*',
  'field.email.fullName': 'Full Name',
  'field.city.label': 'City',
  'field.language.label': 'Language',
  'field.shortText.invalid': 'The text is more than 255 character',
  'field.upload.status.empty': 'Drop files here, or click to select file',
  'field.upload.status.dragged': 'Drop the files here',

  'campaigns.page.title': 'Your Campaigns',
  'campaigns.table.count': 'Available Campaigns: {count}',

  'company.field.logo': 'logo',
  'company.field.title': 'title',
  'company.field.description': 'description',
  'company.field.phone': 'phone number',
  'company.field.website': 'website',
  'company.field.rfmAnalysis': 'RFM Analysis',

  'campaign.type.sms.label': 'Contact Via Sms',
  'campaign.type.sms.createTitle': 'Create a SMS',
  'campaign.type.sms.description': 'Available for VIP accounts',
  'campaign.type.email.label': 'Contact Via Email',
  'campaign.type.email.createTitle': 'Create an Email',
  'campaign.type.email.description': 'Available for VIP accounts',
  'campaign.title': 'Campaign Title',
  'campaign.description': 'Description',
  'campaign.tags': 'Tags',
  'campaign.tags.header': 'Send to:',
  'campaign.email.subject': 'Email Subject*',
  'campaign.email.text': 'Email Text',
  'campaign.sms.text': 'SMS Text',
  'campaign.message.content': 'Additional Info',
  'campaign.allCustomers': 'All customers',
  'campaign.reportPage.button': 'View Reports',
  'campaign.confirmation.message': 'This feature is available for VIP Plan',
  'campaign.send.success': 'Your campaign sent successfully',
  'campaign.customers.count': 'Customer Count: {count} ',
  'campaign.scheduledDate': 'Sent at {date}',
  'campaign.emailSubject': 'Email subject: {subject}',
  'campaign.message': 'Message: {message}',
  'campaign.visitCount': 'Visit count',
  'campaign.sentTags.header': 'Send to',
  'campaign.sendCampaign': 'Send Campaign',
  'campaign.editCampaign': 'Edit Campaign',
  'campaign.stats.customerCount': 'Sent ',
  'campaign.stats.visitCount': 'Visited',
  'campaign.stats.receivedCount': 'Received',
  'campaign.stats.noStats': 'No Stats Yet!',

  'campaign.status.sent': 'sent',
  'campaign.status.draft': 'draft',
  'campaign.status.canceled': 'canceled',
  'campaign.status.scheduled': 'scheduled',

  'languages.select.label': 'Change Language',
  'languages.select.confirmation': 'Are you sure to change language?',

  'dashboard.stats.sentCampaign.total': 'Total Sent Campaigns: {stat} ',
  'dashboard.stats.visitedCampaign.total': 'Total Visited Campaigns: {stat}',
  'dashboard.stats.orders': 'Orders',
  'dashboard.stats.customer.total': 'Total Customers: {stat}',
  'dashboard.stats.customer': 'Total Customers',
  'dashboard.stats.activeForm': 'Form Submits',
  'dashboard.stats.orders.Total': 'Total Orders: {stat}',
  'dashboard.stats.activeForm.total': 'Total form submits: {stat}',
  'dashboard.stats.monthly': 'Monthly',
  'dashboard.stats.daily': 'Daily',
  'dashboard.stats.total.revenue': 'Payments Received',
  'dashboard.stats.total.orders': 'Total Orders',
  'dashboard.stats.total.customers': 'Total Customers',
  'dashboard.stats.total.newCustomers': 'New Customers',
  'dashboard.stats.average.orders': 'Avg. amount of orders',
  'dashboard.stats.average.productsInOrders':
    'Avg. amount of products in each order',
  'dashboard.stats.refundRate': 'ROI Rate',
  'dashboard.stats.average.reordering': 'Avg. returning orders duration (days)',
  'dashboard.stats.duplicatedCustomers': 'Returning Customers',
  'dashboard.stats.ComingSoon': 'More data is required',
  'dashboard.stats.box.value': '{count}',
  'dashboard.welcoming': 'Welcome to ___REACT_APP_WL_TITLE___ CDP!',

  'general.noResult.message': 'no {key}s yet !',
  'general.add': 'Add',
  'general.save': 'Save',
  'general.saveAndContinue': 'Save & Continue',
  'general.saved': 'Changes saved successfully',
  'general.back': 'Back',
  'general.cancel': 'Cancel',
  'general.send': 'Send',
  'general.saveDraft': 'Save as draft',
  'general.dropdown.empty': 'No option found!',
  'general.logout': 'Logout',
  'general.yes': 'Yes',
  'general.no': 'No',

  'general.N/A': 'N/A',
  // page title
  'customer.profile': 'Customer profile',
  'customer.list': 'Customers list',
  'customer.fields': 'Customer fields',
  'customer.activities': 'Customer Activities',
  'customer.notes': 'Customer Notes',
  'tags.list': 'Tag List',
  'tags.mapView': 'Map View',
  'tags.map.filter.deselectAll': 'Deselect all',
  'tags.map.filter.selectAll': 'Select all',
  'tags.map.notEnoughData':
    'You don’t have enough audience/customers or tags. This page will be activated when you reach enough customers and tags at ___REACT_APP_WL_TITLE___ CDP',
  'campaign.list': 'Campaigns List',
  'campaign.create': 'Create Campaign',
  'campaign.edit': 'Campaign Details',
  'campaign.report': 'Campaign Report',
  'user.profile': 'Your Profile',
  'organization.profile': 'Company Profile',
  'organization.create': 'Create Company',
  'organization.edit': 'Edit Company',
  'organization.show': 'Company',
  'organization.list': 'Companies List',
  'integrations.add': 'add source',
  'integrations.description': 'integration description',
  'integrations.list.empty': 'No integrations yet!',
  'integration.create': 'Create Integration',
  'integrations.header':
    'To sync & integrate your data with ___REACT_APP_WL_TITLE___, add your data-source here.',
  'integrations.tabs.overview': 'Overview',
  'integrations.tabs.settings': 'Settings',
  'integrations.tabs.customActions': 'Custom Actions',
  'integration.details.header': 'Sources / {title}',
  'integration.name': 'Name',
  'integration.website': 'Website',
  'integration.website.placeholder': 'https://',
  'integration.enable': 'Enable',
  'integration.enable.description':
    'Enabling this data source will allow it to send data into ___REACT_APP_WL_TITLE___ and any connected enabled destinations.',
  'integration.description': 'Connection details',
  'source.setup': 'Source setup',
  'source.create.success': 'New source added successfully',
  'integration.installation': 'Installation',
  'integration.key': 'API Key',
  'integration.secret': 'Secret Key',
  'integration.WriteOnly': 'Write Only Key',
  'integration.guide': 'Use this guide to configure this source',
  'source.deleted.success': 'Source deleted successfully',
  'source.delete.areYouSure': 'Are you sure to delete this source?',
  'source.updated.success': 'Source updated successfully',
  'source.delete': 'Delete source',
  'source.save': 'Save changes',
  'actions.list.empty': 'No custom actions yet!',
  'actions.list.header': 'Actions',
  'actions.add': 'add action',
  'action.operation.create': 'create',
  'action.operation.read': 'read',
  'action.operation.update': 'update',
  'action.operation.delete': 'delete',
  'action.name': 'Title',
  'action.tags': 'Tags',
  'action.score': 'Score',
  'action.description': 'Description',
  'action.operation.type': 'Operation type',
  'action.create': 'create',
  'action.group.type': 'Group type',
  'action.score.description': '',
  'action.create.successfully': 'New Action created successfully',
  'action.update.successfully': 'Action updated successfully',
  'actions.table.back': 'Back to actions',
  'action.create.header': 'Create new action',
  'action.edit.header': 'Edit action',
  'action.slug': 'Slug',

  // CDP Assistance Bot
  'cdpBot.welcome': 'Hi {user}!',
  'cdpBot.welcome.onboarding': 'Do these tasks to get started',
  'cdpBot.welcome.message':
    'We help your business to grow by connecting you to your customers',
  'cdpBot.username': 'user',
  // Cdp-bot actions
  // add customer
  'cdpBot.actions.add.customer': 'Add new customers',
  'cdpBot.actions.add.customer.types':
    'How would you like to import customers?',
  'cdpBot.actions.add.customer.byFile': 'Upload file',
  'cdpBot.actions.add.customer.byOption': 'Create Customer',
  'cdpBot.actions.import.customer.successfully':
    'New customers imported successfully, check {link}',
  'cdpBot.actions.import.customer.unsuccessfully': 'Importing customers failed',
  'cdpBot.actions.import.customer.unComplete':
    'Importing some customers failed, check error logs to resolve them',
  'cdpBot.actions.import.customer.sample': 'Download sample',
  'cdpBot.actions.add.customer.successfully':
    'New customer created successfully, check {link}',
  'cdpBot.actions.customers.link': 'customers list',
  'cdpBot.actions.add.customer.uploadFile':
    'Upload file from the box down below',
  'cdpBot.actions.add.customer.fullName': 'Enter full name (required)',
  'cdpBot.actions.add.customer.email': 'Enter email',
  'cdpBot.actions.add.customer.phone': 'Enter phone number',
  'cdpBot.actions.add.customer.score':
    "Any initial score? If you don't know, please skip",
  'cdpBot.actions.add.customer.tags':
    'Any tags to assign? you can select multiple tags from the box below',
  'cdpBot.actions.add.customer.fileFormats':
    'only xls, xlsx and csv files allowed!',
  'cdpBot.onboarding.step.show.errors': 'Show errors',

  // onboarding
  'cdpBot.onboarding.step.creatForm.title': 'Create your first form',
  'cdpBot.onboarding.step.creatForm.description':
    'Fill your database with leads from your website using effective online forms - no technical expertise required.',
  'cdpBot.onboarding.step.creatForm.button': 'Create from',
  'cdpBot.onboarding.step.invite.title': 'Invite your team',
  'cdpBot.onboarding.step.invite.description':
    'Evaluate your new tools together.',
  'cdpBot.onboarding.step.invite.button': 'Invite your team',
  'cdpBot.onboarding.step.customer.title': 'Import your contacts',
  'cdpBot.onboarding.step.customer.description':
    'Organize, track, and manage your valuable leads in your CDP contacts database.',
  'cdpBot.onboarding.step.customer.button.import': 'Import contacts',
  'cdpBot.onboarding.step.customer.button.example': 'Download Sample',
  'cdpBot.onboarding.step.customer.button.template': 'Download template',
  'cdpBot.onboarding.step.customer.file': 'File: {file}.',
  'cdpBot.onboarding.step.customer.file.name': 'Uploaded list',
  'cdpBot.onboarding.step.customer.import.status': 'Status: {status}',
  'cdpBot.onboarding.step.customer.import.status.new': 'Uploading',
  'cdpBot.onboarding.step.customer.import.status.queued':
    'In Progress, please check back in a few seconds',
  'cdpBot.onboarding.step.customer.import.status.inProgress': 'Importing',
  'cdpBot.onboarding.step.customer.import.status.imported':
    'Imported successfully',
  'cdpBot.onboarding.step.customer.import.status.failed': 'The import failed',
  'cdpBot.onboarding.step.customer.import.status.canceled':
    'The import is now canceled',
  'cdpBot.onboarding.step.customer.reTry': 'Try again',
  'cdpBot.onboarding.step.skip': 'Skip for now',
  'cdpBot.onboarding.step.skip.all': 'Skip all steps for now',
  'cdpBot.onboarding.step.learnMore': 'Learn more',
  'cdpBot.onboarding.step.completed': "You've completed all the tasks",
  'cdpBot.onboarding.step.mainPageLink': "Let's continue",

  //customer demo
  'cdpBot.customer.demo': 'Import demo data (for testing)',
  'cdpBot.customer.demo.description': 'Importing demo data',

  title: 'Title',
  status: 'Status',
  created_at: 'Created at',
  updated_at: 'Updated at',
  scheduled_at: 'Scheduled at',
  email: 'email',
  sms: 'SMS',
  actions: 'Actions',
  tags: 'Tags',
  color: 'color',
  integrations: 'Connections',
  copy: 'copy',
  copied: 'copied',
  skip: 'skip',
  here: 'here',
  'are you sure?': 'Are you sure?',

  // table
  'table.empty': 'Oops! No Data Available...',
  'table.labelDisplayedRows': '{from}-{to} of {count}',
  'table.moreThan': 'more than {to}',
  'table.recordsCount': '{count} records',

  'filters.condition.label': 'Condition',
  'filters.operator.label': 'Operator',
  'filters.operand.label': 'Value',
  'filters.chip.label': '{field} {condition}: {value}',

  'sortBy.label': 'Sort By',
  'sortBy.asc': 'Asc',
  'sortBy.desc': 'Desc',
  'sortBy.chip.label': 'Sort By: {field} - {direction}',

  'filters.conditions.exact': 'is',
  'filters.conditions.iexact': 'is (case insensitive)',
  'filters.conditions.contains': 'contains',
  'filters.conditions.icontains': 'contains (case insensitive)',
  'filters.conditions.lt': 'less than',
  'filters.conditions.lte': 'less than or equal to',
  'filters.conditions.gt': 'greater than',
  'filters.conditions.gte': 'greater than or equal to',
  filter: 'Filter',
  // pagination
  'pagination.rows per page': 'rows per page',
  'pagination.first page': 'first page',
  'pagination.last page': 'last page',
  'pagination.previous page': 'previous page',
  'pagination.next page': 'next page',

  'tags.tableTitle': 'Tags',

  upgrade: 'Upgrade',
}
