import { AppStorage } from 'utils/persistence'

const defaultLocale = process.env.REACT_APP_DEFAULT_LOCALE

export const isInternationalVersion = () => defaultLocale !== 'fa'

export const appLanguage = () => {
  if (AppStorage.get('app-lng')) return AppStorage.get('app-lng')
  return defaultLocale || 'en'
}

export const isRTL = () => {
  const rightToLeftLanguages = ['fa', 'ar'] //[farsi, arabic]

  if (rightToLeftLanguages.includes(appLanguage())) {
    return true
  }
  return false
}

export const inDevMode = process.env.NODE_ENV === 'development'
export const formBuilderDashboard = process.env.REACT_APP_FORM_BUILDER_DOMAIN
export const customerExcelSample = process.env.REACT_APP_EXCEL_SAMPLE
export const customerExcelTemplate = process.env.REACT_APP_EXCEL_TEMPLATE

export const customLogoUrl = process.env.REACT_APP_CUSTOM_LOGO_URL
