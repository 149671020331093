import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import logo from 'assets/images/logo/logo.png'

import { customLogoUrl } from 'settings'

const useStyles = makeStyles(theme => ({
  logo: {
    width: '155px',
    height: '40px',
    '& > a': {
      display: 'block',
      width: '100%',
      height: '100%',
    },
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
}))

export default function Logo() {
  const classes = useStyles()

  return (
    <div className={classes.logo}>
      <Link to="/">
        <img src={customLogoUrl ? customLogoUrl : logo} alt="Formaloo CDP" />
      </Link>
    </div>
  )
}
