const isWhiteLabel = process.env.REACT_APP_WL_TITLE

const fa = isWhiteLabel
  ? require('./fa.whitelabel.js').default
  : require('./fa.js').default

const en = isWhiteLabel
  ? require('./en.whitelabel.js').default
  : require('./en.js').default

export { en, fa }
